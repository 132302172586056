@import "./main.scss";
.generateLink{
    background-color: black;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    color: $white;
    align-items: center;
    text-align: center;
   
    .titleContainer{
        font-family: 'HurmeSuperBold';

        img{
            @media(max-width: $phone){
                width: 35vw;
            }
        }

        .title{
            font-size: 40px;
            @media(max-width: $phone){
                font-size: 27px;
            }
        }
        @media(max-width: $phone){
            margin-top: 10vh;
        }
    }
    
    .stepsContainer{
        text-align: left;
        width: 35%;
        padding-left: 50px;
        @media(max-width: $phone){
            width: 75%;
            padding: 15px 0px;
        }
    
        .step{
            font-weight: 500;
            position: relative;
        }
       
    }
    
   
}