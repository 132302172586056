@import "./main.scss";
.wollyPay{
    background-color: black;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    color: $white;
    align-items: center;
    text-align: center;
    &.ios{
        .titleContainer{
            @media(max-width: $phone){
                margin-top: 5vh;
            }
            
        }
    }
    .titleContainer{
        img{
            @media(max-width: $phone){
                width: 35vw;
            }
        }

        .title{
            font-size: 40px;
            font-family: 'HurmeSuperBold';

            @media(max-width: $phone){
                font-size: 27px;
                margin-top: 0;
            }
        }
        .subTitle{
            width: 85%;
            margin: 1em auto;
        
        }
        @media(max-width: $phone){
            margin-top: 10vh;
        }
        
    }
   
    .stepsContainer{
        text-align: left;
        width: 35%;
        padding-left: 50px;
        @media(max-width: $phone){
            width: 65%;
            padding: 0;
        }
    
        .step{
            font-weight: 500;
            position: relative;
            &::before{
                background-color: $white;
                border-radius: 50px;
                position: absolute;
                color:$black;
                left: -32px;
                height: 20px;
                width: 20px;
                text-align: center;
                font-weight: 800;
                display: flex;
                justify-content: center;
                align-items: center;
            
            }
            @media(max-width: $phone){
                font-size: 14px;
            }
        }
        .step1::before{
            content:"1"
        }
        .step2::before{
            content:"2"
        }
        .step3::before{
            content:"3"
        }
        .step4::before{
            content:"4"
        }
    }
    
   
    
}