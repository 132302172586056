@import "./main.scss";

.priceModal{
    .currencyContainer{
        min-width: 48%;
        max-width: 48%;
        display: flex;
        align-items: center;
        flex-direction: column;
        @media(max-width: $phone){
            max-width: unset;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 10px;
           
        }
        .button.buttonBlue{
            @media(max-width: $phone){
                display: none;
            } 
        }
        p{
            @media(max-width: $phone){
                display: none;
            }
        }
        .currencyInput{
            border: transparent;
            padding: 20px;
            width: 75%;
            font-size: 40px;
            border-radius: 15px;
            position: relative;
            margin: 0 auto ;
            font-weight: 600;
            &:focus-visible{
                outline: none;
                background-color: $soft-grey
            }
            @media(max-width: $phone){
                padding: 10px 20px;
                font-size: 60px;
               
            }
        }
        .labelCurrency{
            position: relative;
            width: 100%;
            display: flex;
            &::after{
                font-size: 50px;
                font-family: "HurmeSuperBold";
                content: "€";
                position: absolute;
                bottom: 87px;
                left: 22px;
                display: block;
                width: 20px;
                height: 20px;
                color: $warm-grey-2;
                @media(max-width: $phone){
                    left: 0;
                }
                
            }
            @media(max-width: $phone){
                &::after{
                    font-size: 40px;
                    bottom: 85px;
                    left: 4px;
                    color: $warm-grey-2;
                    
                }
            }

        }
    }
    .reviewContainer{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .guarranteeContainer{
        min-width: 48%;
        max-width: 48%;
        @media(max-width: $phone){
            width: 100%;
            min-width: none;
            max-width:none ;
        }
       p{
        font-size: 20px;
        word-wrap: normal;
        @media(max-width: $phone){
            text-align: center;
            font-size: 13px;
        }
       }
        .imagesContainer{
            display: flex;
            align-items: center;
            flex-direction: column;
            .stripeImage{
                width: 400px;
                @media(max-width: $phone){
                    width: 300px;
                }
            }
            .guaranteeImage{
                width: 320px;
                @media(max-width: $phone){
                    width: 190px;
                }
            }
        }
    }
    .buttonMobile{
        display: none;
        @media(max-width: $phone){
            display: block;
            p{
                width: 100%;
                text-align: center;
            }
        }
    }
}