@import "./main.scss";

.details{
    color:$black;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    position: relative;
    .title{
        font-family: 'HurmeSuperBold';
       
    }
    .subTitle{
        @media(max-width: $phone){
            margin-top: 0;
        }
    }
   
    .infoContainer{
        display: flex;
        justify-content: space-between;
        @media(max-width: $phone){
            flex-direction: column;
            align-items: center;
        }
        .jobDetailsContainer{
            max-width: 42vw;
            width: 42vw;
            display: flex;
            flex-direction: column;
            @media(max-width: $phone){
                max-width: unset;
                min-width: 90%;
                align-items: flex-start;
                width: fit-content;
                margin-bottom: 10px;
               
            }
            .yellowLabel{
                height: 40px;
                display: flex;
                width: 102.3%;
                align-items: center;
                padding-left: 20px;
                margin-left: -18px;
                background-color: $coral;      
                @media(max-width: $phone){
                    display: none;
                   
                }  
            }
            .guildContainer{
                display: flex;
                align-items: center;
                height: 40px;                
                width: 100%;   
                p{
                    font-size: 25px;
                    font-weight: 700;
                    margin: 0 0 0 15px  ;
                    font-family: "HurmeSuperBold";
                }
                img{
                    width: 54px;
                    height: 54px;
                    @media(max-width: $phone){
                        width: 50px;
                        height: 50px;
                    }
                }
            }
            .jobDetailsDescriptionContainer{
                display: flex;
                p{
                    margin: 0 0 20px 15px  ;
                    @media(max-width: $phone){
                        font-size: 13px;
                    }
                }
                img{
                    height: 20px;
                    width: 20px;
                    
                }

            }
            h1{
                margin-top: 10px;
                font-weight: 100;
                @media(max-width: $phone){
                    font-size: 20px;
                }
            }
        }
        .wollersContainer{
            max-width: 42vw;
            width: 42vw;
            @media(max-width: $phone){
                flex-direction: column;
                align-items: center;
                max-width: unset;
                align-items: flex-start;
                width: 100%;
               margin-top: 15px;
            }
            .title{

                @media(max-width: $phone){
                    margin-top: 0;
                }                 
            }  
           
            .wollerContainer{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 55px;
                margin-top: 20px;
                @media(max-width: $phone){
                    margin-top: 10px;
                }
               
                .wollerDataContainer{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    img{
                        width: 70px;
                        margin-right: 10px;
                        border-radius: 50%;
                        border: transparent;
                    }
                    p{
                        margin-right: 5px;
                    }
                }
                .finalArrow{
                    font-size: 40px;
                }
            }
        }
    }
}